function getSlug(){
    const href = window.location.href;
    const split = href.split("/")
    const res = "/"+split[split.length-1];
    return res
}

export function getDirectory(){
    const href = window.location.href;
    const split = href.split("/")
    const res = "/"+split[split.length-2];
    return res
}

export function getUser(){
    const href = window.location.href;
    const split = href.split("/")
    const res = split[split.length-1];
    return res
}

export default getSlug

