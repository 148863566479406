import { DateType } from "@/types/datesSection"

export default (showsArray:DateType[])=>{
    // return showsArray
    
    const newShowsArray = showsArray.sort((a,b)=>{
        const diff = ((new Date(a.fullDateString||0)).getTime()-new Date(b.fullDateString||0).getTime())


        return diff 
    })
    return newShowsArray
}