import "./index.css"
    type Props = {
        src:string
    }

const Video = ({src}:Props)=>{
        const urlSplit = src.split('watch?v=')
        const embedURL = urlSplit[0]+"embed/"+urlSplit[1]
        return(
            <iframe className="video" src={embedURL}></iframe>
        )
}

export default Video
    
    
    