import "./index.css"
import EditDateForm from "@/components/EditDateForm"
import { Context, ContextValueType } from "@/context"
import BigContainer from "@/generics/BigContainer"
import EditComponentHeader from "@/generics/EditComponentHeader"
import EventDetails from "@/generics/EventDetails"
import Section from "@/generics/Section"
import handleToggleFeature from "@/handlers/handleToggleFeature"
import DatesSectionType, { DateType } from "@/types/datesSection"
import EditComponentProps from "@/types/editComponentProps"
import checkIfEmptyFields from "@/utils/checkIfEmptyFields"
import checkShouldRender from "@/utils/checkShouldRender"
import getTimeFromDate, { getDateFromString } from "@/utils/getTimeFromDate"
import { reformatDate, reformatTime } from "@/utils/stringFormatting"
import { useContext, useEffect, useState } from "react"
import { addDate, deleteDateCall } from "@/api/dates"
import sortShows from "@/utils/sortShows"

    const Dates = ({pageDetails,ownPage,isEditing,currentPageEdit,setCurrentPageEdit,pendingChanges,setPendingChanges}:EditComponentProps)=>{
        const componentName = "datesSection"
        const shows:any[] = (pageDetails?.datesSection.dates)||[]
        const editingState = currentPageEdit?.[componentName]
        const on = editingState?.on

        const turnOnFeature = async ()=>{
            handleToggleFeature({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges})
        }

        const setComponentValues = (value:any)=>{
            const newCurrentPageEdit = {...currentPageEdit};
            if(!newCurrentPageEdit?.[componentName]?.dates){
                return
            }
            newCurrentPageEdit[componentName].dates = value.dates;
            setCurrentPageEdit(newCurrentPageEdit)
        }

        if(!currentPageEdit?.datesSection||(on===undefined)){
            return<></>
        }
        
        if(ownPage&&isEditing){
            return(
                <Section alternating={true}>
                    <EditComponentHeader
                    componentName="Dates Section"
                    on={on}
                    turnOnFeature={turnOnFeature}
                ></EditComponentHeader>
                {on?<EditDates datesSection={currentPageEdit.datesSection}
                setDatesSection={setComponentValues}
                pendingChanges={pendingChanges}
                setPendingChanges={setPendingChanges}
                
                
                ></EditDates>:<></>}
                </Section>

            )
        }
        if(!checkShouldRender({pageDetails,componentName})){
            return<></>
        }
        const displayShows = ([...shows.map(show=>{return{...show}})])
        displayShows.forEach(show=>{
            if((typeof show?.date === "string") &&show.date.length>13){
                const fullDateString = show.date
                show.time = reformatTime({dateString:fullDateString})
                show.date = reformatDate(fullDateString)
                const date = new Date(fullDateString)
                show.day = date.toString().substring(0,3)
                show.fullDateString = fullDateString
            }
        })
        const sortedShows = sortShows(displayShows)
     

        return(
            <Section>
                <h2 className="section-heading" id="dates">Upcoming Dates</h2>
                <BigContainer>
                    {displayShows.map((show,key)=>{
                        return(
                            <EventDetails key={key} eventDetails={show}></EventDetails>
                        )})}
                </BigContainer>
            </Section>
        )
    }
    export default Dates
    
    type EditDatesProps = {
        datesSection:any,
        setDatesSection:any
        pendingChanges:any,
        setPendingChanges:any
    }
    
    function EditDates({datesSection,setDatesSection,pendingChanges,setPendingChanges}:EditDatesProps){
        const dates:DateType[] = datesSection.dates
        const {credentials}:ContextValueType = useContext(Context)
        const userName = credentials.userName
        const templateDate:DateType = {date:"2023-12-31",time:"20:00",location:"The Place",city:"Anytown, NY",link:"www.***replace-this-address***.com"}
        const [datesForForm,setDatesForForm] = useState([...dates])

        useEffect(()=>{
            if(datesSection.dates.length!==datesForForm.length){
                setDatesForForm(datesSection.dates)
            }
        },[datesSection.dates])

        useEffect(()=>{
            const newDates = [...datesForForm].filter(date=>{
                return !checkIfEmptyFields(date)
            })
            setDatesSection({...datesSection,dates:newDates})
            const newChanges = {...pendingChanges}
            newChanges["datesSection.dates"] = newDates
            setPendingChanges(newChanges)
        },[datesForForm])

        datesForForm.forEach(show=>{
            if(show?.date&&show.date.length>10){
                show.time = getTimeFromDate(show.date)
                show.date = getDateFromString(show.date)

            }
        })

        const handleAddDate = async ()=>{
            const resp:any = await addDate({userName,date:templateDate})
            const data = resp.data
            const newDate = data;
            newDate.time = getTimeFromDate(newDate.date)
            newDate.date = getDateFromString(newDate.date)
            
            const newDatesSection:DatesSectionType = {...datesSection}
            const newDatesList = [...datesSection.dates,newDate]
            
            newDatesSection.dates = newDatesList
            setDatesSection(newDatesSection)
        }

        return(
            <div className="edit-dates-cont">
                {datesForForm.map((date:DateType,key)=>{
                    return(<EditDateForm index={key} date={date} key={key}
                    datesForForm={datesForForm}
                        
                        setDatesForForm={setDatesForForm}/>)
                })}
                <h1 className="add-date">Add Date
                    <button onClick={handleAddDate}>+</button>
                    </h1>
            </div>
        )
    }
    