import getTimeFromDate, { getDateFromString } from "./getTimeFromDate"

const months = [
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"
]
const ordinals = [
    "th","st","nd","rd","th","th","th","th","th","th"
]

export const reformatDate = (dateString:string)=>{
    const date = getDateFromString(dateString)
    const split = date.split("-")
    const monthNumber = (+split[1]-1)
    const reformattedDate = months[monthNumber]+" "+getOrdinal(split[2])
    return reformattedDate
}

export const reformatTime = ({dateString,timeString}:{dateString?:string,timeString?:string})=>{
    let time
    if(dateString){
        time = getTimeFromDate(dateString)
    }else{
        time=timeString
    }
    if(!time){
        return
    }
    let [hour,minute] = time.split(":")
    const hourNumber = +hour

    const hourNumberReformatted = hourNumber===0?12:hourNumber%12
    const reformattedMinute = minute=="00"?"":`:${minute}`
    const meridian = hourNumber<12?"AM":"PM"
    const reformattedTime = `${hourNumberReformatted}${reformattedMinute} ${meridian}`
    return reformattedTime
}

export const getOrdinal = (input:(number|string))=>{
    const num = +input;
    const onesDigit = num%10;
    let ordinal = ordinals[onesDigit]
    if(num>4&&num<21){
        ordinal = "th"
    }
    return num+ordinal
}

export const t = (string:string)=>{
    const result = string.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}