import Register from "@/pages/Register"
import Dates from "@/pages/Dates"
import Home from "@/pages/Home"
import Main from "@/pages/Main"
import Messages from "@/pages/Messages"
import Users from "@/pages/Users"

import {Route, Routes} from "react-router-dom"

const Router = ()=>{

        return(<>
            <Routes>
                <Route path={`/register/`} element={<Register></Register>}></Route>
                <Route path={`/users/`} element={<Users></Users>}></Route>
                <Route path={`/messages/`} element={<Messages></Messages>}></Route>
                <Route path={`/users/*`} element={<Home></Home>}></Route>
                <Route path={`/*`} element={<Main></Main>}></Route>
            </Routes>
        </>
        )
    }
export default Router
    
    
    