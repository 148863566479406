
    import login from "@/api/login"
import register from "@/api/register"
import { Context, ContextValueType } from "@/context"
import Form from "@/generics/Form"
import { validateRegistration } from "@/utils/yup"
import React, { useContext, useState } from "react"

    const Register = ()=>{
        const [errors,setErrors]:[errors:{field:string,statement:string}[],setErrors:any] = useState([])

        const {setCredentials}:ContextValueType = useContext(Context)

        const Recaptcha = null

        const handleSubmit =async  (formValues:any)=>{
            const validation = await validateRegistration(formValues)
            if(validation.isError){

                setErrors(validation.errors.map((error:string)=>{
                    return {field:error.split(" ")[0],statement:error}
                }))
                return
            }           
            const data = await register(formValues)
            if(data.error){
                setErrors([data.error])
                return
            }
            await login(formValues,setCredentials)
        }

        return(
            <Form
            fields={[
            {name:"userName"},
            {name:"name"},    
            {name:"email",type:"email"},
            {name:"password",type:"password"},
            {name:"confirmPassword",type:"password"}
                
        ]}
        errors={errors}
            inline={true}
            submitName="Sign up"
            buttonClass="btn-primary"
            handleSubmit={handleSubmit}
            recaptcha={true}
            ></Form>
        )
    }
    export default Register
    
    
    