
    import "./index.css"
    import ThreeGrid from "@/generics/ThreeGrid"
import React from "react"

    const AppThreeGrid = ()=>{

        const elements = [
            {heading:"Musicians",image:"/musician.jpg",paragraph:"Where are you touring? Put out a new banger? Did the lead singer get bangs? Let your fans know about it."},
            {heading:"Comedians",image:"/comedian.jpg",paragraph:"You're a comedian, so you have an IG, a twitter, a tik-tok, etc etc. We get it. Well, you can still have a webpage. "},
            {heading:"Podcasters",image:"/podcaster.png",paragraph:"Your listeners don't know what you look like. Well, we can change that, or, um, you can put up a stock photo of podcasters instead. Doesn't matter to us."}
        ]

        return(
            <ThreeGrid
            elements={elements}
            ></ThreeGrid>
        )
    }
    export default AppThreeGrid
    
    
    