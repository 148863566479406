import './App.css';
import { ContextProvider } from '@/context';
import Router from './Router';
import Nav from './generics/Nav';
import PersonalPageHeader from './components/PersonalPageHeader';
function App() {
  return (
    <ContextProvider>
        <Router></Router>

    </ContextProvider>
  );
}

export default App;
