import getJWT from "./getJWT"
import route from "./route"


export const fexGet = async (urlEnd:string,body:any)=>{
    const url = route(urlEnd)
    const resp = await fetch(url)
    const data = await resp.json()
    return data
}

export const fexPost = async (urlEnd:string,body:any)=>{
    const url = route(urlEnd)

    
    const resp = await fetch(url,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
        },
        body:JSON.stringify(body)
    })
    const data = await resp.json()
    return data
}

export const fexDelete = async (urlEnd:string,body:any)=>{
    
    const url = route(urlEnd)
    const resp = await fetch(url,{
        method:'DELETE',
        headers:{
            'Content-Type':"application/json"
        },
        body:JSON.stringify(body)
    })
    const data = await resp.json()
    return data
}

export const fexPut = async (urlEnd:string,body:any)=>{
    const url = route(urlEnd)

    const resp = await fetch(url,{
        method:'PUT',
        headers:{
            'Content-Type':"application/json",
            'Authorization': `Bearer ${getJWT()}`
        },
        body:JSON.stringify(body)
    })
    const data = await resp.json()
    return data
}
