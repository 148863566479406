    import "./index.css"
    import React from "react"
import { FaArrowDown, FaArrowUp} from "react-icons/fa"

    const DraglessReorder = ({rows,setCurrentRows}:any)=>{

        const rowsArray = []
        for (let field in rows){
            const ordinal = rows[field]
            rowsArray[rows[field]] ={field:field,ordinal:rows[field]}
        }


        const handleUp = (field:string,ordinal:number)=>{
            const editedRows = {...rows}
            for(let row in editedRows){
                if(row===field){
                    editedRows[row]--
                }else if(editedRows[row]===(ordinal-1)){
                    editedRows[row]++
                }
            }
            setCurrentRows(editedRows)
        }

        const handleDown = (field:string,ordinal:number)=>{
            const editedRows = {...rows}
            for(let row in editedRows){
                if(row===field){

                    editedRows[row]++
                }else if(editedRows[row]===(ordinal+1)){
                    editedRows[row]--
                }
            }
            setCurrentRows(editedRows)
        }

        return(
            <div>
                {rowsArray.filter((r)=>{
                    return r.ordinal
                }).map((row,index)=>{
                    return(
                        <div className="dragless-reorder-div">
                            {row.field}
                            <div>
                                <FaArrowDown className="down" onClick={()=>handleDown(row.field,row.ordinal)}></FaArrowDown>
                                <FaArrowUp className="up" onClick={()=>handleUp(row.field,row.ordinal)}></FaArrowUp>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
    export default DraglessReorder
    
    
    