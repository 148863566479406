import PageDetails from "@/types/pageDetails"

type Arguments = {
    pageDetails:any,
    componentName:string
}

const checkShouldRender = ({pageDetails,componentName}:Arguments)=>{
    if(!pageDetails?.[componentName]||!pageDetails[componentName].on){
        return false
    }
    return true
}

export default checkShouldRender