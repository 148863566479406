import "./index.css"
    import React from "react"

    type Props = {
        setter:Function,
        tabs:{name:string,value:any}[],
        state:any
    }

    const Tabs = ({setter,tabs,state}:Props)=>{
        return(
            <div className="tabs-cont">
                {tabs.map((tab,key)=>{
                    return <div key={key} className={`tab ${tab.value===state&&"selected"}`} onClick={()=>setter(tab.value)}>{tab.name}</div>
                })}
                <div className="empty-space"></div>
            </div>
        )
    }
    export default Tabs
    
    
    