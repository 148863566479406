import "./index.css"
import BigQuote from "@/generics/BigQuote"

    const Testimonials = ()=>{

        const quote1 = {quote:"Hey, this service is brand new.",attribution:"Jane Smith",title:"CEO of You Inc.",image:"/incognito.png"}
        const quote2 = {quote:"So, obviously, we do not have any testimonials.",attribution:"John Smith",title:"CEO of Inc.",image:"/incognito.png"}
        const quote3 = {quote:"If you do enjoy the service, let us know. We can put your mug up here.",attribution:"Jane Smith",title:"CEO of Inc.",image:"/incognito.png"}
        const quote4 = {quote:"... and your name, of course.",attribution:"John Smith",title:"CEO of Inc.",image:"/incognito.png"}
        const quotes = [quote1,quote2,quote3,quote4]
        return(
            <section className="quotation-section">
                {quotes.map((q,k)=>{
                    return(<BigQuote key={k} quote={q.quote} attribution={q.attribution} title={q.title} image={q.image}></BigQuote>)
                })}
            </section>
        )
    }
    export default Testimonials
    
    
    