import "./index.css"
    
type Props = {
    alternating?:boolean
    children?:(JSX.Element|JSX.Element[])
}

    const Section = ({alternating,children}:Props)=>{
        return(
            <section className={`section ${alternating?"alternating":""}`}>
                {children}
            </section>
        )
    }
    export default Section
    
    
    