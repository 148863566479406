import { Link } from "@/types/generics"
import { fexDelete, fexPost, fexPut } from "@/utils/fex"

export const addLink = async ({userName}:{userName:string})=>{
    const link = {href:"",text:""}
    const data = await fexPost(`/api/users/${userName}/links`,link)
    return data
}

export const editLinks = async ({userName,links}:{userName:string,links:Link[]})=>{
    const data = await fexPut(`/api/users/${userName}/links`,{links})
}



export const deleteLink = async (userName:string,linkId:string)=>{
    const res = await fexDelete(`/api/users/${userName}/links`,{linkId})
}