    import "./index.css"
    import React from "react"

    type Props = {
        senderName:string,
        senderEmail:string,
        message:string
    }

    const MessageDiv = ({senderName,senderEmail,message}:Props)=>{
        return(
            <div className="message-cont">
                <header>

                <h3>{senderName}</h3>
                <h4>{senderEmail}</h4>
                </header>
                <p>{message}</p>
            </div>
        )
    }
    export default MessageDiv
    
    
    