import { Context, ContextValueType } from "@/context"
import { selectTheme } from "@/handlers/selectTheme"
import { useContext } from "react"
import "./index.css"    
    const ThemeSelection = ()=>{

        const context:ContextValueType = useContext(Context)

        const handleSelect = (e:React.FormEvent<HTMLSelectElement>)=>{
            selectTheme(e,context)
        }

        return(
            <section className="section">
                <h1>Select Your Theme</h1>
                <select value={context.theme} onChange={handleSelect}>
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                    <option value="blue">Blue</option>
                </select>
            </section>
        )
    }
    export default ThemeSelection
    
    
    