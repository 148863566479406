import "./index.css"
    import { DateType } from "@/types/datesSection"
import Setter from "@/types/setter";
import React, { useContext, useState } from "react"
import DeleteCircle from "@/generics/DeleteCircle";
import {reformatDate, reformatTime} from "@/utils/stringFormatting"
import { fexDelete } from "@/utils/fex";
import { Context, ContextValueType } from "@/context";
import { deleteDateCall } from "@/api/dates";

    const EditDateForm = ({date,index,datesForForm,setDatesForForm}:{date:DateType,index:number,datesForForm:DateType[],setDatesForForm:Setter})=>{
        
        const {credentials}:ContextValueType = useContext(Context)
        const userName = credentials.userName
        const [isOpen,setIsOpen] = useState(false)

        const handleChange = (e:any)=>{
            const {name,value} = e.target;
            const newDatesForForm:DateType[] = [...datesForForm]
            if(!(typeof value ==="string")){
                return
            }
            newDatesForForm[index][name as keyof DateType] = value;
            setDatesForForm(newDatesForForm)
        }

        const handleDelete:React.MouseEventHandler<HTMLButtonElement> =async (e)=>{
            if(!(e.target instanceof HTMLButtonElement)){
                return
            }
            const {id:dateId} = e.target.dataset;
            if(!(typeof dateId==="string")){
                return
            }
            const newDatesForForm = [...datesForForm].filter(date=>{
                return (date._id!==dateId)
            })
            setDatesForForm(newDatesForForm)
            const data = await deleteDateCall({userName,dateId})
        }

        return(
        <div className="event-form-cont">
        <div className="top-area">
        <h1>
            <span>
            {`${reformatDate(date.date)}`}
            </span>
            <span>
                {date.location}
            </span>
            </h1>
            <h4>            
                <span>
                {`${reformatTime({timeString:date.time})}`}
            </span>
            <span>
                {date.city}
            </span></h4>
        <button onClick={()=>{setIsOpen(!isOpen)}}>{isOpen?"Close":"Edit"} Details</button>
        </div>
        {isOpen&&<form onChange={handleChange}>
            <table className="event-table">
                <tbody>

            <tr>
                <td className="emphasize">
                    <input placeholder="Date" name="date" value={date.date} type="date"></input>
                </td>
                <td className="right emphasize">
                <input placeholder="Location" name="location" value={date.location}></input>
                    </td>
            </tr>
            <tr>
                <td>
                <input onChange={handleChange} placeholder="Time" type="time" name="time" value={date.time}></input>
                    </td>
                <td className="right">
                <input onChange={handleChange} placeholder="City, State" name="city" value={date.city}></input>
                    </td>
            </tr>
            <tr>
                <td colSpan={2}>
                <input className="full-span" onChange={handleChange} placeholder="Event Link" type="text" name="link" value={date.link}></input>
                </td>
            </tr>
                </tbody>
            </table>
        </form>}
        <DeleteCircle
        _id={date?._id||index.toString()}
        handleDelete={handleDelete}
        ></DeleteCircle>
        </div>
        )
    }
    export default EditDateForm
    
    
    