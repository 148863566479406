import "./index.css"
    import React from "react"

    type Props = {
        quote:string,
        attribution:string
        title?:string
        image?:string
    }

    const BigQuote = ({quote,attribution,title,image}:Props)=>{
        return(
            <div className="quote-section">
            <div className="img-cont">
            <img src={image}></img>
            </div>
            <div className="quote">

            <h2>
            <span>"{quote}"</span>
            </h2>

            </div>
            <div className="attribution-section">

            <span className="attribution" >{attribution}</span>
            {title&&<span> {title}</span>}
            </div>
            </div>
        )
    }
    export default BigQuote
    
    
    