import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import React from "react"
import Form from "@/generics/Form"
import EditComponentProps from "@/types/editComponentProps"
import handleToggleFeature from "@/handlers/handleToggleFeature"
import EditComponentHeader from "@/generics/EditComponentHeader"
import { postMessage } from "@/api/messages"

    const Contact = ({pageDetails,ownPage,isEditing,currentPageEdit,setCurrentPageEdit,pendingChanges,setPendingChanges}:EditComponentProps)=>{
        const componentName = "contactSection"
        const editingState = currentPageEdit?.[componentName]
        const on = editingState?.on
        
        const turnOnFeature = async ()=>{
            handleToggleFeature({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges})
        }

        // This is if we decide to add more editable fields in the future.

        // const setComponentValues = (value:any)=>{
        //     const newCurrentPageEdit = {...currentPageEdit};
        //     newCurrentPageEdit[componentName] = value;
        //     setCurrentPageEdit(newCurrentPageEdit)
        // }
        if(!currentPageEdit?.[componentName]||(on===undefined)){
            return<></>
        }

        if(isEditing){
            return(
            <Section alternating={true}>
            <EditComponentHeader
            componentName="Contact Section"
            on={on}
            turnOnFeature={turnOnFeature}
            ></EditComponentHeader>
            {on?(<>
            <SectionHeading
                text={"Contact Me"}
                ></SectionHeading>
            <Form
            inline={true}
            buttonClass="btn-primary"
            fields={[{name:"Name",type:"text"},{name:"Email",type:"email"},{name:"Message",type:"textArea"}]}
            ></Form>
            </>):<></>
            }
            </Section>
            )
        }

        if(!on){
            return<></>
        }

        return(
            <Section alternating={true}>
            <SectionHeading
                text={"Contact Me"}
                ></SectionHeading>
            <Form
            handleSubmit={postMessage}
            inline={true}
            buttonClass="btn-primary"
            fields={[{name:"Name",type:"text"},{name:"Email",type:"email"},{name:"Message",type:"textArea"}]}
            ></Form>
            </Section>        )
    }
    export default Contact
    
    
    