import PageDetails from "@/types/pageDetails"

export const getRows = (pageDetails:any)=>{

    const rows:any = {
        hero:1,
        videoSection:2,
        bio:3,
        socialsSection:4,
        datesSection:5,
        contactSection:6,
        linksSection:7
    }
    for(let field in pageDetails){
        if(pageDetails?.[field]?.ordinal){
            rows[field] = pageDetails?.[field]?.ordinal
        }
    }
    

    return rows
}