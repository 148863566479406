
    import MainPageHeader from "@/components/MainPageHeader";
import { Context, ContextValueType } from "@/context"
import MessageDiv from "@/generics/MessageDiv";
import React, { useContext, useState } from "react"

    const Messages = ()=>{
        const {messages}:ContextValueType = useContext(Context)
        const [initialRegister,setInitialRegister] = useState(false)

        
        return(
<>
            <MainPageHeader
            setInitialRegister={setInitialRegister}
            >
    
    
    
            </MainPageHeader>
            <div>

            <h1>Inbox</h1>
            {messages.map((message:any,index:number)=>{
                return (
                <>
                <MessageDiv
                    senderName={message.senderName}
                    senderEmail={message.senderEmail}
                    message={message.message}
                ></MessageDiv>
                {/* <div key={index}>
                    <h3>{message.senderName}</h3>
                    <h4>{message.senderEmail}</h4>
                    <p>{message.message}</p>
                </div> */}
                </>
                )
            })}
            </div>
            </>
        )
    }
    export default Messages
    
    
    