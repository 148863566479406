import { fexPost } from "@/utils/fex";
import { getUser } from "@/utils/getSlug";

export const postMessage = async ({Name,Email,Message}:{Name:string,Email:string,Message:string})=>{
   const senderName = Name;
   const senderEmail = Email;
   const message = Message;
   const userName = getUser()
   const resp = await fexPost("/api/message/"+userName,{senderEmail,senderName,message})

}