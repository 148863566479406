import "./index.css"

type Props = {
    children:JSX.Element|JSX.Element[]
}

    const BigContainer = ({children}:Props)=>{
        return(
            <div className="big">{children}</div>
        )
    }
    export default BigContainer
    
    
    