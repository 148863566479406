    import "./index.css"
    import React from "react"

    type Props = {
        text:string,
        additionalClasses?:string
    }

    const OneLiner = ({text,additionalClasses}:Props)=>{
        return(
            <div className={`one-liner ${additionalClasses||""}`}>{text}</div>
        )
    }
    export default OneLiner
    
    
    