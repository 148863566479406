const route = (urlEnd:string,options?:any)=>{
    if(window.location.origin==="http://localhost:3000"&&(!(urlEnd.indexOf("//")>0))){
        const res = "http://localhost:3005"+urlEnd

        return res
    }

    return urlEnd
}

export default route