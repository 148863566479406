import "./index.css"
import React, { useState } from "react"
import Login from "../Login"
import Register from "../Register"
import Tabs from "../../generics/Tabs"

type Props = {
    initialRegister?:boolean
}

    const Authentication = ({initialRegister}:Props)=>{

        const [register,setRegister] = useState(initialRegister||false)

        const handleTabs = (value:boolean)=>{
            setRegister(value)
        }

        return(
            <div>
                <Tabs
                state={register}
                setter={handleTabs}
                tabs={[{name:"Login",value:false},{name:"Register",value:true}]}
                
                ></Tabs>
            {register?<Register></Register>:<Login></Login>}
            </div>
        )
    }
    export default Authentication
    
    
    