import "./index.css"
import { Context, ContextValueType } from "@/context"
import BigImage from "@/generics/BigImage"
import ImageEditor from "@/generics/ImageEditor"
import OneLiner from "@/generics/OneLiner"
import Section from "@/generics/Section"
import Toggle from "@/generics/Toggle"
import handleToggleFeature from "@/handlers/handleToggleFeature"
import EditComponentProps from "@/types/editComponentProps"
import HeroType from "@/types/hero"
import checkShouldRender from "@/utils/checkShouldRender"
import route from "@/utils/route"
import { useContext } from "react"


    const Hero = ({pageDetails,ownPage,isEditing,currentPageEdit,setCurrentPageEdit,pendingChanges,setPendingChanges}:EditComponentProps)=>{
        
        const componentName = "hero"
        const editingState = currentPageEdit?.[componentName]
        const on = editingState?.on

        const turnOnFeature = async ()=>{
            handleToggleFeature({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges})
        }

        const setComponentValues = (value:any)=>{
            const newCurrentPageEdit = {...currentPageEdit};
            newCurrentPageEdit[componentName] = value;
            setCurrentPageEdit(newCurrentPageEdit)
        }
        
        if(ownPage&&isEditing){
            return(
                <Section alternating={true}>
                <div className="toggle-header">

                <h1 className={!on?"faded":""}>Hero Section</h1>
                <div className="toggle-cont">
                </div>

                <Toggle initialValue={on} action={turnOnFeature}></Toggle>
                <div>{on?"on":"off"}</div>
                </div>
                {on?<EditHero pendingChanges={pendingChanges} setPendingChanges={setPendingChanges} hero={editingState} setHero={setComponentValues}></EditHero>:<></>}
                </Section>
            )
        }
        if(!checkShouldRender({pageDetails,componentName})||!pageDetails?.[componentName]){
            return<></>
        }
        
        let {title,subtitle,image} = pageDetails?.[componentName]
        if(!(typeof image==="string")){
            image = ""
        }
        const modifiedImageURL = image.indexOf("docUpload:")===0?`${route("/api/upload")}/${image.replace("docUpload:","")}`:image
        return(
            <div className="hero-comp">

            <Section alternating={true}>
            <h1>{title||"Hello!"}</h1>
            {subtitle?<OneLiner
            text={subtitle}
            ></OneLiner>:<></>}
            {image?<BigImage url={modifiedImageURL}></BigImage>:<></>}
        </Section>
            </div>
        )
    }
    export default Hero
    
    type EditHeroProps = {
        hero:HeroType,
        setHero:any,
        pendingChanges:any,
        setPendingChanges:any
    }

    function EditHero({hero,setHero,pendingChanges,setPendingChanges}:EditHeroProps){
        const {credentials}:ContextValueType = useContext(Context)
        
        const handleEdit = (e:any)=>{
            const {value,name} = e.target
            const newElement:any = {...hero}
            newElement[name] = value
            setHero(newElement)
            const newChanges = {...pendingChanges}
            newChanges["hero."+name] = value
            setPendingChanges(newChanges)
        }

        const handleEditImage = (url:string)=>{
            
            const newElement:any = {...hero}
            newElement.image = url
            setHero(newElement)
            const newChanges = {...pendingChanges}
            newChanges["hero.image"] = url
            setPendingChanges(newChanges)
        }
        const routeStr = route('/api/upload')

        
        return(
            <div className="hero-comp">
            <div className="edit-section">
            <input onChange={handleEdit} name="title" className="h1" value={hero.title}></input>
            <input onChange={handleEdit} name="subtitle" className="h3" value={hero.subtitle}></input>
            <ImageEditor
            url={hero?.image||""}
            handleEdit={handleEditImage}
            credentials={credentials}
            field="hero.image"
            route={routeStr}
            ></ImageEditor>
            </div>
            </div>

        )
    }
    