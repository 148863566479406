    import "./index.css"
    import React, { useState } from "react"

    type Props = {
        action:any,
        initialValue?:boolean
    }

    const Toggle = ({action,initialValue}:Props)=>{

        const [toggleValue,setToggleValue] = useState(initialValue||false)
        const handleCheckbox = ()=>{
            setToggleValue(!toggleValue)
            action()

        }


        return(
            <label className="switch">
                <input  checked={toggleValue} onClick={handleCheckbox} type="checkbox"></input>
                <span className="slider round"></span>
            </label>
        )
    }
    export default Toggle
    
    
    