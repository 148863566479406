import { fexPost, fexPut } from "@/utils/fex"
import {PageDetails} from "@/types/pageDetails"
type EditSiteParameters = {
    userId:string,
    userName:string,
    fields:object
}

type EditResponse = {
    message:string,
    err:string,
    site?:PageDetails
}

const editSite = async ({userId,userName,fields}:EditSiteParameters):Promise<EditResponse>=>{
    const urlEnd:string = `/api/users/${userName}`  
    const data:EditResponse = await fexPut(urlEnd,{userId,fields})
    return data
}

export default editSite