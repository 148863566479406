
    import React from "react"

    type Props = {
        handleSave:any
    }

    const SaveButton = ({handleSave}:Props)=>{
        return(
            <button onClick={handleSave} className="btn-primary">Save</button>
        )
    }
    export default SaveButton
    
    
    