import "./index.css"

import { Context, ContextValueType } from "@/context"
import { useContext, useEffect, useState } from "react"


// components
import Hero from "@/components/Hero"
import VideoSection from "@/components/VideoSection"
import SocialsSection from "@/components/SocialsSection"
import Bio from "@/components/Bio"
import Dates from "../Dates"
import Contact from "@/components/Contact"
import PersonalPageHeader from "@/components/PersonalPageHeader"

import getPageDetails from "@/api/getPageDetails"
import checkIfOwnPage from "@/utils/checkIfOwnPage"
import BackDrop from "@/generics/BackDrop"
import ThemeSelection from "@/components/ThemeSelection"
import { getRows } from "@/utils/ordinals"
import ReorderComponents from "@/components/ReorderComponents"
import LinksSection from "@/components/LinksSection"

    const Home = ()=>{
    
    
    const {credentials,theme,setTheme}:ContextValueType = useContext(Context)
    const ownPage = checkIfOwnPage({credentials});

    
    // These will be passed down into the components
    
    const [pageDetails,setPageDetails] = useState(null)
    // switch this back!
    const [isEditing,setIsEditing] = useState(false)
    const [currentPageEdit,setCurrentPageEdit] = useState(null)
    const [pendingChanges,setPendingChanges] = useState({})
    const [rows,setRows] = useState(getRows({}))

    useEffect(()=>{
        if(pageDetails == null){
            return
        }

        if(pageDetails["theme"]&&theme!==pageDetails["theme"]){
            setTheme(pageDetails["theme"])
            setRows(getRows(pageDetails))
        }
    },[pageDetails])

    useEffect(()=>{
        const cb = async ()=>{
            const pageDetailsResp = await getPageDetails()
            if(!pageDetailsResp||pageDetailsResp?.error){
            return
            }
            setPageDetails(pageDetailsResp)
            setCurrentPageEdit(pageDetailsResp)
        }
        cb()
    },[])

    
    
    if(!pageDetails){
        return<h1>No Page with that name</h1>
    }
   

    return(
        <>
        <PersonalPageHeader
         ownPage={ownPage}
         isEditing={isEditing}
         setIsEditing={setIsEditing} 
         pendingChanges={pendingChanges}
         setPendingChanges={setPendingChanges}
         />
        <main className={theme+"-theme"}>
            <BackDrop></BackDrop>
            {isEditing&&<ThemeSelection></ThemeSelection>}
            {isEditing&&<ReorderComponents setRows={setRows} rows={rows}></ReorderComponents>}
            <div className="component-grid">
                <div style={{gridRow:rows.hero}}  className="component-cont">
                    <Hero 
            pageDetails={pageDetails}
            ownPage={ownPage}
            isEditing={isEditing}
            currentPageEdit={currentPageEdit}
            setCurrentPageEdit={setCurrentPageEdit}
            pendingChanges={pendingChanges}
            setPendingChanges={setPendingChanges}
                    ></Hero>
                </div>
                <div style={{gridRow:rows.videoSection}}  className="component-cont">
                        <VideoSection
                sectionHeading="Tapes"        
                pageDetails={pageDetails}
                ownPage={ownPage}
                isEditing={isEditing}
                currentPageEdit={currentPageEdit}
                setCurrentPageEdit={setCurrentPageEdit}
                pendingChanges={pendingChanges}
                setPendingChanges={setPendingChanges}
                />
                </div>
                <div style={{gridRow:rows.bio}}  className="component-cont">
                        <Bio
                pageDetails={pageDetails}
                ownPage={ownPage}
                isEditing={isEditing}
                currentPageEdit={currentPageEdit}
                setCurrentPageEdit={setCurrentPageEdit}
                pendingChanges={pendingChanges}
                setPendingChanges={setPendingChanges}
                />
                </div>
                <div style={{gridRow:rows.socialsSection}}  className="component-cont">

            <SocialsSection
            pageDetails={pageDetails}
            ownPage={ownPage}
            isEditing={isEditing}
            currentPageEdit={currentPageEdit}
            setCurrentPageEdit={setCurrentPageEdit}
            pendingChanges={pendingChanges}
            setPendingChanges={setPendingChanges}
            ></SocialsSection>
                </div>
            <div style={{gridRow:rows.datesSection}}  className="component-cont">
<Dates 
        pageDetails={pageDetails}
        ownPage={ownPage}
        isEditing={isEditing}
        currentPageEdit={currentPageEdit}
        setCurrentPageEdit={setCurrentPageEdit}
        pendingChanges={pendingChanges}
        setPendingChanges={setPendingChanges}
        ></Dates>
            </div>
            <div style={{gridRow:rows.contactSection}}  className="component-cont">
            <Contact
                pageDetails={pageDetails}
                ownPage={ownPage}
                isEditing={isEditing}
                currentPageEdit={currentPageEdit}
                setCurrentPageEdit={setCurrentPageEdit}
                pendingChanges={pendingChanges}
                setPendingChanges={setPendingChanges}
                />
            </div>
            <div style={{gridRow:rows.linksSection}}  className="component-cont">
            <LinksSection
                pageDetails={pageDetails}
                ownPage={ownPage}
                isEditing={isEditing}
                currentPageEdit={currentPageEdit}
                setCurrentPageEdit={setCurrentPageEdit}
                pendingChanges={pendingChanges}
                setPendingChanges={setPendingChanges}
                />
            </div>
            </div>
        </main>
        </>
    )

        return(
            <>
            {/* <PersonalPageHeader ownPage={ownPage}/> */}
            <main>
                {/* <Hero ownPage={ownPage}></Hero> */}

                {/* <Contact ownPage={ownPage}></Contact> */}
            </main>
            </>
        )
    }
export default Home
    
    
    