import { DateType } from "@/types/datesSection"
import { fexDelete, fexPost } from "@/utils/fex"

export const deleteDateCall = async ({userName,dateId}:{userName:string,dateId:string})=>{
    const data = await fexDelete(`/api/users/${userName}/date`,{userName,dateId})
    return data
}

type AddDateProps = {
    userName:string,
    date:DateType
}

export const addDate = async ({userName,date}:AddDateProps)=>{
    const data = await fexPost(`/api/users/${userName}/date`,date)
    return data
}