    import "./index.css"
    import { Context, ContextValueType } from "@/context"
import Header from "@/generics/Header"
import Nav from "@/generics/Nav"
import Toggle from "@/generics/Toggle"
import React, { useContext } from "react"
import SaveButton from "@/generics/SaveButton"
import editSite from "@/api/editSite"
import Setter from "@/types/setter"
import PendingChangesType from "@/types/pendingChanges"
import route from "@/utils/route"

type Props = {
    ownPage:boolean,
    isEditing:boolean,
    setIsEditing:Setter,
    pendingChanges:PendingChangesType,
    setPendingChanges:Setter
}


    const PersonalPageHeader = ({ownPage,isEditing,setIsEditing,pendingChanges,setPendingChanges}:Props)=>{
        const {theme,credentials}:ContextValueType = useContext(Context)
        const pendingProperties = Object.keys(pendingChanges)
        
        const isPending = pendingProperties.length>0?true:false

        return(
            <div className={(ownPage?"own-page-header":"other-page-header")+` ${theme}-theme`}>

            <Header>
            <div className="header-left personal">
            <a href="/">
                    <img className="header-logo" src="/mic-stan-logo.png"></img>
                    <h2>Mic Stan</h2>
            </a>
            
            </div>
            {(credentials.userName==="")?(<a href="/register">
                <button className="btn-primary">Make your own page</button>
            </a>):<></>}
            {!ownPage?<></>:
                isPending?(<PageSave
                    pendingChanges={pendingChanges} 
                    setPendingChanges={setPendingChanges}
                    ></PageSave>):
                    (<div className="toggle-cont editing-toggle">
                <div>{isEditing?"editing":"toggle to edit"}</div>
                <Toggle
                action={()=>setIsEditing(!isEditing)}
                ></Toggle>
                </div>)
            }
            </Header>
            </div>
            
        )
    }
    export default PersonalPageHeader
    
    type PageSaveProps = {
        pendingChanges:PendingChangesType,
        setPendingChanges:Setter,
    }


    function PageSave({pendingChanges,setPendingChanges}:PageSaveProps){
        const context:ContextValueType = useContext(Context)
        const {credentials} = context
        const handleSave = async ()=>{
            const res = await editSite({userId:credentials.userId,userName:credentials.userName,fields:pendingChanges})
            setPendingChanges({})
            setTimeout(()=>window.location.reload(),300)
        }

        return(<div className="save-btn-cont">
        <SaveButton handleSave={handleSave}></SaveButton>
        </div>)
    }
    
    