    import "./index.css"
    import React, { useEffect, useState } from "react"
import Video from "../Video"
import DeleteCircle from "../DeleteCircle"
import { FaFile, FaImage, FaLandmark } from "react-icons/fa"
import { handleAddPictureForm, handleDragEnter, handleDragLeave, handleDragOver, handleDrop } from "./handlers"
import Modal from "../Modal"

    type Props = {
        credentials?:any,
        field?:string,
        route:string,
        handleEdit:Function,
        url:string,
        isVideo?:boolean
    }

    const ImageEditor = ({credentials,field,handleEdit,url,isVideo,route}:Props)=>{
        
        const [isModalOpen,setIsModalOpen] = useState(false)
        const [imageUrl,setImageUrl] = useState(url)
        const [visibleImageUrl,setVisibleImageUrl] = useState(url)
        const [isDropOpen,setIsDropOpen] = useState(false)
        const [imageAdded,setImageAdded] = useState(false)
        const [imageKey,setImageKey] = useState(null)
        const handleChange = (e:any)=>{
            const {value} = e.target

            setImageUrl(value)
            setIsDropOpen(false)
        }

        useEffect(()=>{
            setVisibleImageUrl(imageUrl)
            handleEdit(imageUrl)
        },[imageUrl])

        const handleDelete = (e:any)=>{
            setImageUrl("")
            setIsDropOpen(true)
        }

        if(isVideo){
            return(
                <div className="edit-img-cont">
                <Video src={visibleImageUrl}></Video>
                <input placeholder="You can paste an image url here." onChange={handleChange} value={imageUrl}></input>
                </div>
            )
        }
        const modifiedImageURL = visibleImageUrl.indexOf("docUpload:")===0?`${route}/${visibleImageUrl.replace("docUpload:","")}`:visibleImageUrl
        
        return(
            <div className="edit-img-cont dropable">
            {!isDropOpen&&<>
            <DeleteCircle
            _id={"delete"}
            handleDelete={handleDelete}
            
            ></DeleteCircle>
            <img src={modifiedImageURL}></img>
            <input placeholder="You can paste an image url here." onChange={handleChange} value={imageUrl}></input>
            </>
            }
            {isDropOpen&&<>
            <div className="drop-area"
            onDragEnter={handleDragEnter} 
            onDragLeave={handleDragLeave} 
            onDragOver={handleDragOver} 
            onDrop={(e)=>{handleDrop({e,handleEdit,imageAdded,setImageAdded,setImageUrl,setIsDropOpen,setImageKey,credentials,field,route})}}
            >
                <div className="dotted-div"></div>
                <FaImage size={140} className="file-icon"></FaImage>
                <h2>You can drag and drop a jpg or png here.</h2>
                </div>
                {isModalOpen&&(
                    
                    <Modal
                    modalTitle="Choose a File"
                    setIsModalOpen={setIsModalOpen}
                    >
                    <form onSubmit={(e)=>handleAddPictureForm(e,{setImageAdded,setIsDropOpen,credentials,field,route,setIsModalOpen,setImageUrl,handleEdit})}>

                    <input type="file"></input>
                    <button className="btn-primary">Submit Picture</button>
                    </form>
                </Modal>
                    )
                }
                <button className="open-file-modal-btn" onClick={()=>{setIsModalOpen(true)}}>Or click here to upload</button>    
                            <input placeholder="Otherwise, paste an image url here." onChange={handleChange} value={imageUrl}></input>
            </>

                }
            </div>
        )
    }
    export default ImageEditor
    
    
    