import "./index.css"

type Props = {
    url:string
}

    const BigImage = ({url}:Props)=>{
        return(
            <img src={url} className="big-image"></img>
        )
    }
    export default BigImage
    
    
    