
    import { Context, ContextValueType } from "@/context"
import BigContainer from "@/generics/BigContainer"
import EditComponentHeader from "@/generics/EditComponentHeader"
import ImageEditor from "@/generics/ImageEditor"
import OneLiner from "@/generics/OneLiner"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import Toggle from "@/generics/Toggle"
import Video from "@/generics/Video"
import handleToggleFeature from "@/handlers/handleToggleFeature"
import PageDetails from "@/types/pageDetails"
import Setter from "@/types/setter"
import VideoSectionType from "@/types/videoSection"
import checkShouldRender from "@/utils/checkShouldRender"
import route from "@/utils/route"
import React, { useContext, useState } from "react"

type Props = {
    sectionHeading?:string
    pageDetails:PageDetails,
    ownPage:boolean,
    isEditing:boolean,
    currentPageEdit:PageDetails
    setCurrentPageEdit:Setter
    pendingChanges:object
    setPendingChanges:Setter
}

    const VideoSection = ({pageDetails,ownPage,isEditing,currentPageEdit,setCurrentPageEdit,pendingChanges,setPendingChanges}:Props)=>{
        
        const sectionHeading = "Video Section"
        const componentName = "videoSection"
        const editingState = currentPageEdit?.[componentName]
        const on = editingState?.on

        const videos = pageDetails?.videoSection?.videos
        const [videoIndex,setVideoIndex] = useState(0)
        const video = videos?.[videoIndex]

        const turnOnFeature = async ()=>{
            handleToggleFeature({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges})
        }

        const setComponentValues = (value:any)=>{
            const newCurrentPageEdit = {...currentPageEdit};
            newCurrentPageEdit[componentName] = value;
            setCurrentPageEdit(newCurrentPageEdit)
        }

        if(on===undefined){
            return <></>
        }
        
        if(ownPage&&isEditing){
            return(
                <Section alternating={true}>
                <EditComponentHeader
                componentName={sectionHeading}
                on={on}
                turnOnFeature={turnOnFeature}
                ></EditComponentHeader>
                {on?<EditVideoSection pendingChanges={pendingChanges} setPendingChanges={setPendingChanges} videoSection={editingState} setVideoSection={setComponentValues}></EditVideoSection>:<></>}
                </Section>
            )
        }
        if(!checkShouldRender({pageDetails,componentName})||(video?.title===undefined)||(video?.url===undefined)){
            return<></>
        }



        return(
            <Section alternating={true}>
            {!sectionHeading?<></>:<SectionHeading
            text={sectionHeading}
            ></SectionHeading>}
            <BigContainer>
            <OneLiner additionalClasses="text-left lower-case" text={video.title}></OneLiner>
            <Video src={video.url}></Video>
        </BigContainer>

        </Section>
        )
    }
    export default VideoSection
    
    
    type EditVideoSectionProps = {
        videoSection:VideoSectionType,
        setVideoSection:any,
        pendingChanges:object,
        setPendingChanges:any
    }

    function EditVideoSection({videoSection,setVideoSection,pendingChanges,setPendingChanges}:EditVideoSectionProps){
        const {credentials}:ContextValueType = useContext(Context)
        const handleEdit = (e:any)=>{
            const {value,name} = e.target
            const newElement:any = {...videoSection}
            if(name==="title"){
                newElement.videos[0].title = value
            }
            setVideoSection(newElement)
            const newChanges:any = {...pendingChanges}
            newChanges["videoSection.videos.0."+name] = value
            setPendingChanges(newChanges)
        }

        const handleEditImage = (url:string)=>{
            const newElement:any = {...videoSection}
            newElement.videos[0].url = url
            setVideoSection(newElement)
            const newChanges:any = {...pendingChanges}
            newChanges["videoSection.videos.0.url"] = url
            setPendingChanges(newChanges)
        }
        const routeStr = route('/api/upload')
        return(
            <div className="edit-section">
            <input placeholder="Video Title Here" onChange={handleEdit} name="title" className="h1" value={videoSection.videos[0].title}></input>
            <ImageEditor
             isVideo={true} url={videoSection?.videos?.[0]?.url||""} handleEdit={handleEditImage}
             credentials={credentials}
             field="videoSection.video[0].url"
             route={routeStr}
             
             
             
             ></ImageEditor>
            </div>

        )
    }