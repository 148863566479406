
    import AppFooter from "@/components/AppFooter"
import AppThreeGrid from "@/components/AppThreeGrid"
import Authentication from "@/components/Authentication"
import MainPageHeader from "@/components/MainPageHeader"
import Testimonials from "@/components/Testimonials"
import Why from "@/components/Why"
import { Context, ContextValueType } from "@/context"
import Hero from "@/generics/Hero"
import Modal from "@/generics/Modal"
import React, { useContext, useEffect, useState } from "react"

    const Main = ()=>{
        const context:ContextValueType = useContext(Context)
        const {modalOpen,setModalOpen,credentials} = context
        const [initialRegister,setInitialRegister] = useState(false)
        const [loggedIn,setLoggedIn] = useState(false)

        const handleHeroCTA = ()=>{
            setInitialRegister(true)
            setModalOpen("authentication")
        }

        useEffect(()=>{
            setModalOpen("authentication")
            setInitialRegister(true)
        },[])

        return(
        <>
        <MainPageHeader
        setInitialRegister={setInitialRegister}
        >



        </MainPageHeader>
        <main>
            <Hero
            image="/micstanhero.png"
            heading="Show off your goods."
            paragraph="We can help you build a free performers page in a matter of minutes."
            ctaText="Start Here."
            handleHeroCTA={handleHeroCTA}
            ></Hero>
            <AppThreeGrid></AppThreeGrid>
            <Why></Why>
            <Testimonials></Testimonials>
            <AppFooter></AppFooter>
        </main>
            {modalOpen==="authentication"&&<Modal
            additionalClass="authentication-modal"            
            >
                <Authentication
                initialRegister={initialRegister}
                ></Authentication>
                </Modal>}
            </>
        )
    }
    export default Main
    
    
    