import {createContext, useEffect, useState} from "react"
import linksJSON from "@/jsons/links.json"
import customJSON from "@/jsons/custom.json"
import Social from "@/types/social"
import { getDirectory } from "@/utils/getSlug"
import { link } from "fs"
import getSite from "@/utils/getSite"
import HeroType from "@/types/hero"
import VideoSectionType from "@/types/videoSection"
import BioType from "@/types/bio"
import SocialsSectionType from "@/types/socialsSectionType"
import DatesSectionType from "@/types/datesSection"
import { fexGet } from "@/utils/fex"
type ContextType={
    Provider:any
    Consumer:any
}

export const Context:ContextType = createContext({})

export type ContextValueType = {
    credentials:any,
    setCredentials:any,
    messages:any,
    modalOpen:(string|null),
    setModalOpen:any,
    links:any[],
    hero:HeroType,
    videoSection:VideoSectionType,
    bio:BioType,
    socialsSection:SocialsSectionType,
    datesSection:DatesSectionType,
    socials:Social[],
    title:string,
    callToAction:{heading:string,buttonText:string},
    topImage:string
    video1:{title:string,src:string},
    video2:{title:string,src:string},
    callToAction2:{heading:string,buttonText:string,img:string},
    dates:any[],
    userName:string,
    siteData:any,
    ownSite:boolean,
    editing:boolean,
    setEditing:React.Dispatch<React.SetStateAction<boolean>>,
    pendingChanges:any,
    setPendingChanges:any,
    theme:string,
    setTheme:React.Dispatch<React.SetStateAction<string>>
}


export function ContextProvider({children}:{children:any}){

    let initialCred
    if(sessionStorage.getItem("micStanCred")){
        const credJSON = JSON.parse(sessionStorage.getItem("micStanCred")||"")
        if(credJSON?.userName){
            initialCred=credJSON
        }

    }else{
        initialCred = {userName:""}
    }
    const [credentials,setCredentials] = useState(initialCred)
    const [userName,setUserName] = useState(getDirectory())
    const links = linksJSON.map(link=>{return{...link,route:`${userName}${link.route}`}})
    const [siteData,setSiteData] = useState(null)
    const [modalOpen,setModalOpen] = useState(null)
    const [pendingChanges,setPendingChanges] = useState({})
    const [theme,setTheme] = useState("blue")
    const [messages,setMessages] = useState([])
    let ownSite
    if(credentials&&credentials?.userName){
        ownSite = (("/"+credentials.userName)===userName)
    }
    const [editing,setEditing] = useState(true)
    
    useEffect(()=>{
        getSite(userName).then(site=>setSiteData(site))
    },[userName])
    
    useEffect(()=>{
        if(credentials.userName===""){
            return
        }
        const getAndSetMessages = async ()=>{
            const messages = await fexGet("/api/message/"+credentials.userName,{})
            setMessages(messages)
            
        }
        getAndSetMessages()
    },[credentials])
    if(!siteData){
        return(
            <Context.Provider value={{userName}}>
            </Context.Provider>
        )
    }

    const {hero,videoSection,socialsSection,datesSection,
        bio,
    } = siteData
    

    return(
        <Context.Provider value={{
        messages,setMessages,
        theme,setTheme,
        userName,
        credentials,setCredentials,
        pendingChanges,setPendingChanges,
        editing,setEditing,
        modalOpen,setModalOpen,
        hero,
        videoSection,
        socialsSection,
        datesSection,
        links,siteData,bio,ownSite}}>
            {children}
        </Context.Provider>
    )
}
