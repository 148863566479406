import "./index.css"

type Props = {
    image:string,
    heading?:string,
    alt?:string,
    paragraphs:string[]
}

    const TextAndImage = ({image,heading,paragraphs,alt}:Props)=>{
        return(
            <section className="text-and-image">
                <img alt={alt||""} src={image}></img>
                <div className="text-cont">
                    {heading&&<h2>{heading}</h2>}
                    {paragraphs.map((p,k)=>{
                        return<p key={k}>{p}</p>
                    })}
                </div>
            </section>
        )
    }
    export default TextAndImage
    
    
    