import { Context, ContextValueType } from "@/context"
import BigContainer from "@/generics/BigContainer"
import BigImage from "@/generics/BigImage"
import EditComponentHeader from "@/generics/EditComponentHeader"
import ImageEditor from "@/generics/ImageEditor"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import Toggle from "@/generics/Toggle"
import handleToggleFeature from "@/handlers/handleToggleFeature"
import BioType from "@/types/bio"
import EditComponentProps from "@/types/editComponentProps"
import checkShouldRender from "@/utils/checkShouldRender"
import route from "@/utils/route"
import React, { useContext, useState } from "react"

    const Bio = ({pageDetails,ownPage,isEditing,currentPageEdit,setCurrentPageEdit,pendingChanges,setPendingChanges}:EditComponentProps)=>{
        
        const componentName = "bio"
        const editingState = currentPageEdit?.[componentName]
        const on = editingState?.on

        const turnOnFeature = async ()=>{
            handleToggleFeature({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges})
        }

        const setComponentValues = (value:any)=>{
            const newCurrentPageEdit = {...currentPageEdit};
            newCurrentPageEdit[componentName] = value;
            setCurrentPageEdit(newCurrentPageEdit)
        }

        if(ownPage&&isEditing){
            if(on===undefined){
                return<></>
            }
            return(
                <Section alternating={true}>
                <EditComponentHeader
                componentName="Bio"
                on={on}
                turnOnFeature={turnOnFeature}
                ></EditComponentHeader>
                {on?<EditBio bio={editingState} setBio={setComponentValues} pendingChanges={pendingChanges} setPendingChanges={setPendingChanges}></EditBio>:<></>}
                </Section>
            )
        }
        
        if(!checkShouldRender({pageDetails,componentName})){
            return<></>
        }
        if(!pageDetails?.[componentName]){
            return<></>
        }
        const {img,text} = pageDetails?.[componentName]
        const modifiedImageURL = img.indexOf("docUpload:")===0?`${route("/api/upload")}/${img.replace("docUpload:","")}`:img
        return(
            <Section alternating={true}>
                <SectionHeading
            text="Bio"
            ></SectionHeading>
            <BigImage
            url={modifiedImageURL}
            ></BigImage>
            <BigContainer>
                <p className="text-left normal-spacing">
                {text}
                </p>
            </BigContainer>
        </Section>
        )
    }
    export default Bio
    
    type EditBioProps = {
        bio:BioType,
        setBio:any
        pendingChanges:any,
        setPendingChanges:any
    }
    
    function EditBio({bio,setBio,pendingChanges,setPendingChanges}:EditBioProps){
        const {credentials}:ContextValueType = useContext(Context)
        const handleEdit = (e:any)=>{
            const {value,name} = e.target
            const newElement:any = {...bio}
            newElement[name] = value
            setBio(newElement)
            const newChanges = {...pendingChanges}
            newChanges["bio."+name] = value
            setPendingChanges(newChanges)
        }

        const handleEditImage = (url:string)=>{
            const newElement:any = {...bio}
            newElement.url = url
            setBio(newElement)
            const newChanges = {...pendingChanges}
            newChanges["bio.img"] = url
            setPendingChanges(newChanges)
        }
        const routeStr = route('/api/upload')
        return(
            <div className="edit-section">
            <ImageEditor
            url={bio.img||""}
            handleEdit={handleEditImage}
            credentials={credentials}
            field="bio.image"
            route={routeStr}
            ></ImageEditor>
            <textarea value={bio.text} onChange={handleEdit} name="text" className="h1">
            </textarea>
            </div>

        )
    }