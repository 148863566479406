
    import React from "react"
import { FaCircle, FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"

    const SocialSymbol = ({name,size}:{name:string,size:number})=>{
    
        if(name==="facebook"){
            return <FaFacebook className="social-icon" size={size}></FaFacebook>
        }
        if(name==="twitter"){
            return <FaTwitter className="social-icon" size={size}></FaTwitter>
        }
        if(name==="instagram"){
            return <FaInstagram className="social-icon" size={size}></FaInstagram>
        }
        if(name==="youtube"){
            return <FaYoutube className="social-icon" size={size}></FaYoutube>
        }


            return <FaCircle></FaCircle>


    }
    export default SocialSymbol
    
    
    