import { fixHref } from "@/utils/links"
import "./index.css"
type Props = {
        eventDetails:any
    }

    const EventDetails = ({eventDetails}:Props)=>{
        return(
            <table className="event-table">
                <tbody>

                <tr>
                    <td className="emphasize">{eventDetails.day}</td>
                    <td className="right emphasize">{eventDetails.location}</td>
                </tr>
                <tr>
                    <td>{eventDetails.date}</td>
                    <td className="right">{eventDetails.city}</td>
                </tr>
                <tr>
                    <td>{eventDetails.time}</td>
                    <td className="right">
                        {eventDetails.link===""?<></>:<a href={fixHref(eventDetails.link)}>Event Details</a>}
                        </td>
                </tr>
                </tbody>
            </table>
        )
    }
    export default EventDetails
    
    
    