import "./index.css"
    import { Context, ContextValueType } from "@/context"
import DraglessReorder from "@/generics/DraglessReorder"
import { fexPost, fexPut } from "@/utils/fex"
import React, { useContext, useEffect, useState } from "react"

    const ReorderComponents = ({rows}:any)=>{
        const {credentials}:ContextValueType = useContext(Context)
        const [currentRows,setCurrentRows] = useState(rows)
        const [reorderOpen,setReorderOpen] = useState(false)

        const handleSetCurrentRows = async (rows:any)=>{
            setCurrentRows(rows)
            fexPut(`/api/users/${credentials.userName}/rows`,rows)
        }
        useEffect(()=>{
            setCurrentRows(rows)
        },[rows])


        return(
            <section className="section reorder-section">
                <button onClick={()=>setReorderOpen(true)}>Reorder Components</button>
                {reorderOpen&&(
                <div>
                <h3>New Order will appear on save.</h3>
                <DraglessReorder rows={currentRows} setCurrentRows={handleSetCurrentRows}></DraglessReorder>
                </div>
                
                )}
            
            </section>
        )
    }
    export default ReorderComponents
    
    
    