import "./index.css"
    import React from "react"

    type Props = {
        _id:string,
        handleDelete:any
    }

    const DeleteCircle = ({_id,handleDelete}:Props)=>{
        return(
            <button data-id={_id} onClick={handleDelete} className="delete-circle">×</button>
        )
    }
    export default DeleteCircle
    
    
    