    import "./index.css"
    import React from "react"

    type Props = {
        children:JSX.Element
        count?:number
    }

    const ItemWithFloatCount = ({children,count=0}:Props)=>{
        return(
            <div className="floater-cont">
                {count>0&&<div className="floater"><span>
                    {count}
                    </span>
                    </div>}
                {children}
            </div>
        )
    }
    export default ItemWithFloatCount
    
    
    