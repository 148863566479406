import { UserAPIObject } from "@/types/apiCalls"
import { UserType } from "@/types/user"
import { fexGet } from "@/utils/fex"
import route from "@/utils/route"

export type UserSummary = {
    userName:string,
    name:string,
    image:string
}

export const getAllUsers = async ():Promise<UserSummary[]>=>{
    try{

        const data = await fexGet("/api/allUsers",{})
        if(Array.isArray(data)){
            const newData = data.map((user)=>{
                const image:string = route(user.image)
                const newUser:UserSummary = {...user,image}
                return newUser
            })
            return newData
        }
        return []
    }catch(err){
        return []
    }
}