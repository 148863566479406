import "./index.css"
import { getAllUsers, UserSummary } from "@/api/allUsers"
import Authentication from "@/components/Authentication"
import MainPageHeader from "@/components/MainPageHeader"
import UserDetails from "@/components/UserDetails"
import { Context, ContextValueType } from "@/context"
import Modal from "@/generics/Modal"
import React, { SetStateAction, useContext, useEffect, useState } from "react"

    const Users = ()=>{
        const context:ContextValueType = useContext(Context)
        const {modalOpen,setModalOpen,credentials} = context
        const [initialRegister,setInitialRegister] = useState(false)
        const [loggedIn,setLoggedIn] = useState(false)
        const [users,setUsers] = useState<UserSummary[]>([])

        useEffect(()=>{
            const fetchUsers =  async ()=>{
                const allUsers = await getAllUsers()

                setUsers(allUsers)
            }
            fetchUsers()
        },[])

        return(
            <>
            <MainPageHeader>
            </MainPageHeader>
            <main className="users-list-main">

            <h1>Users List</h1>
            <div className="users-list">
                {users.map((user)=>{

                    return (<UserDetails
                    user={user}
                    
                    
                    ></UserDetails>)
                })}
            </div>
            </main>
            {modalOpen==="authentication"&&<Modal
            additionalClass="authentication-modal"            
            >
                <Authentication
                initialRegister={initialRegister}
                ></Authentication>
                </Modal>}
            </>
        )
    }
    export default Users
    
    
    