const getSite = async (userName:string)=>{
    try{

        const resp = await fetch(`http://localhost:3005/api/users${userName}`)
        const data = await resp.json()
        return data
    }catch(err){
        return {message:"no site"}
    }
}

export default getSite