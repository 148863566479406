import "./index.css"
    import Section from "@/generics/Section"
import TextAndImage from "@/generics/TextAndImage"

    const Why = ()=>{
        const image = "/why.jpg"
        const heading = "What can MicStan do for me?"
        const para1 = "A dedicated webpage for a performer offers a lot that social media cannot. Pictures, Bio, Dates, a Contact Form, all are perfectly ordered for someone who needs to check you out. They may not generate a lot of traffic, but a dedicated page is great to send to bookers. "
        const para2 = "Building your own website or hiring someone to do it, can be a huge unnecessary investment in time or money. With MicStan, you can have what you need in a matter of minutes without any coding or cost to you. "
        const paragraphs = [para1,para2]
        return(
            <div className="why-style">
            <Section>
            <TextAndImage
            image={image}
            alt={"Taken by Paul Hudson from United Kingdom, Creative Commons Attribution 2.0"}
            heading={heading}
            paragraphs={paragraphs}
            
            
            ></TextAndImage>
            </Section>
            </div>
        )
    }
    export default Why
    
    
    