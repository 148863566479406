import editSite from "@/api/editSite"
import { ContextValueType } from "@/context"

export const selectTheme = (e:React.FormEvent<HTMLSelectElement>,context:ContextValueType)=>{
    const target = e.target as HTMLSelectElement
    const theme = target.value
    const {setTheme,credentials} = context
    const {userId,userName} = credentials
    setTheme(theme)
    editSite({userId,userName,fields:{theme}})
}