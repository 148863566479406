import PageDetails from "@/types/pageDetails"
import PendingChangesType from "@/types/pendingChanges"
import Setter from "@/types/setter"

type Arguments = {
    currentPageEdit:any,
    setCurrentPageEdit:Setter,
    componentName:any,
    pendingChanges:object,
    setPendingChanges:Setter
}

// TODO need to retype this.

const handleToggleFeature = ({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges}:Arguments)=>{
    if(!currentPageEdit||!currentPageEdit?.[componentName]){
        return
    }
    const isOn:any = !(currentPageEdit[componentName].on)
    const newPageEdit = {...currentPageEdit}
    newPageEdit[componentName].on = isOn    
    setCurrentPageEdit({...newPageEdit})

    const newChanges:PendingChangesType = {...pendingChanges}
    newChanges[`${componentName}.on`] = newPageEdit[componentName].on           
    setPendingChanges(newChanges)
}

export default handleToggleFeature