import "./index.css"
import { UserSummary } from "@/api/allUsers"

    const UserDetails = ({user}:{user:UserSummary})=>{
        const {userName,name} = user
        
        return(
            <div className="user-details">
                <div className="user-details-img-cont">
                <img src={user.image}></img>
                </div>
                <a href={`/users/${userName}`}>
                    <h3>{name}</h3>
                </a>
            </div>
        )
    }
    export default UserDetails
    
    
    