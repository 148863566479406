
    import React from "react"
import Toggle from "../Toggle"

    type Props = {
        componentName:string,
        on:boolean,
        turnOnFeature:()=> Promise<void>
    }

    const EditComponentHeader = ({componentName,on,turnOnFeature}:Props)=>{
        return(
            <>
                <div className="toggle-header">

                <h1 className={!on?"faded":""}>{componentName}</h1>
                <div className="toggle-cont">
                <Toggle initialValue={on} action={turnOnFeature}></Toggle>
                <div>{on?"on":"off"}</div>
                </div>
                </div>
            </>
        )
    }
    export default EditComponentHeader
    
    
    