import { fexGet } from "@/utils/fex"
import getSlug from "@/utils/getSlug"

const getPageDetails = async ()=>{
    const pageName:string = getSlug().substring(1)
    const route = `/api/users/${pageName}`
    const data = await fexGet(route,{})
    return data
}

export default getPageDetails