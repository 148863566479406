import "./index.css"
    import React from "react"

    const BackDrop = ()=>{
        return(
            <div className="back-drop"></div>
        )
    }
    export default BackDrop
    
    
    