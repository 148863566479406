import "./index.css"
import Footer from "@/generics/Footer"

    const AppFooter = ()=>{

        const footerLinksList1 = {
            heading:"Category One",
            links:[
                {text:"Link 1",href:"www.google.com"},{text:"Link 1",href:"www.google.com"},{text:"Link 1",href:"www.google.com"},{text:"Link 1",href:"www.google.com"}
            ]
        }
        const footerLinksList2 = {
            heading:"Category Two",
            links:[
                {text:"Link 1",href:"www.google.com"},{text:"Link 1",href:"www.google.com"},{text:"Link 1",href:"www.google.com"},
                {text:"Link 1",href:"www.google.com"},{text:"Link 1",href:"www.google.com"},{text:"Link 1",href:"www.google.com"}
            ]
        }
        const footerLinksLists = [footerLinksList1,footerLinksList2]

        const socials = {}

        return(
            <Footer footerLinksLists={[]} socials={socials}>
                <p>This site is currently in beta, there are bugs, missing features, and definitely things that are just ugly. Feel free to reach out to me any time with issues or suggestions: joechristiansonwebdev@gmail.com</p>
            </Footer>
        )
    }
    export default AppFooter
    
    
    