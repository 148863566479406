import "./index.css"
import { Context, ContextValueType } from "@/context"
import React, { useContext, useEffect, useState } from "react"
import Setter from "@/types/setter"
import EnvelopeIcon from "@/generics/InboxIcon"
import UserCount from "../UserCount"

    type Props = {
        setInitialRegister?:Setter,
        children:JSX.Element|JSX.Element[]
    }

    const MainPageHeader = ({setInitialRegister,children}:Props)=>{
        const context:ContextValueType = useContext(Context)
        const {modalOpen,setModalOpen} = context
        const {userName:myName} = context.credentials
        const [scrolled,setScrolled] = useState(false)
        const handleScroll = (e:any)=>{
            const top = !(window.scrollY>0)
            if(!scrolled&&!top){
                setScrolled(true)
            }
            else if(top){
                setScrolled(false)
            }

        }

        useEffect(()=>{
            window.addEventListener("scroll",handleScroll)
        },[])

        return(
            <header className={`main-header ${scrolled?"scrolled":""}`}>
                <div className="header-left">
                <a href="/">
                    <img className="header-logo" src="/mic-stan-logo.png"></img>
                    <h2>Mic Stan</h2>
                </a>

                </div>
                {/* We'll add some links down here! */}
                <div className="header-center">
                    {/* <UserCount></UserCount> */}
                </div>
                <div className="header-right">
                {myName&&<a className="btn-secondary" href={`/users/${myName}`}>My page</a>}
                {myName&&<a href="/messages" className="env-link">
                    <EnvelopeIcon></EnvelopeIcon>
                    </a>
                    }
                {!myName&&<>
                <button className="btn-primary" onClick={()=>{setModalOpen("authentication");setInitialRegister(false)}}>Login</button>
                <button className="btn-secondary" onClick={()=>{setModalOpen("authentication");setInitialRegister(true)}}>Register</button>
                </>
                }
                </div>
                {children}
            </header>
        )
    }
    export default MainPageHeader
    
    
    