import route from "@/utils/route"

const uploadFile = async ({file,setImageAdded,setIsDropOpen,credentials,field,route}:any)=>{

    let url = route
    let formData = new FormData()
  
    formData.append('image', file)
    formData.append("field",field)
    formData.append("credentials",JSON.stringify(credentials))
    
    
    const resp = await fetch(url, {
      method: 'POST',
      body: formData
    });
    const data= await resp.json()

    // setImageKey(data.imagePath.split("/")[2])
    return data
  }


const handleFiles = async ({files,setImageAdded,setImageKey,credentials,field,route}:any)=>{
    let data;
    for (let i = 0;i<files.length;i++){
      data = await uploadFile({file:files[i],setImageAdded,setImageKey,credentials,field,route})
    }
    return data
}

export const handleDragEnter=(e:any)=>{
    e.preventDefault();
    e.target.classList.add("highlight")
}
export const handleDragLeave=(e:any)=>{
    e.preventDefault();
    e.target.classList.remove("highlight")
}
export const handleDragOver=(e:any)=>{
    e.stopPropagation();
    e.preventDefault()
}
export const handleDrop=async ({e,setIsDropOpen,setImageAdded,setImageUrl,setImageKey,handleEdit,credentials,field,route}:any)=>{

    
    e.preventDefault()
    e.target.classList.remove("highlight")

    const dt = e.dataTransfer;
    const files = dt.files;
    const resp = await handleFiles({files,setImageAdded,setImageKey,credentials,field,route})

    if(resp.success){

        setImageUrl("docUpload:"+resp.key)
        setImageAdded(true)
        setIsDropOpen(false)
        // At the end of this, assuming success, we pass the image path to the handler on the specific component's level.
        handleEdit(resp.key)
    }
    }


    export const handleAddPictureForm =async  (e:any,{setImageAdded,setIsDropOpen,setImageUrl,handleEdit,credentials,field,route,setIsModalOpen}:any)=>{
      e.preventDefault()

      const file = e.target[0].files[0]
      const resp = await uploadFile({file,setImageAdded,setIsDropOpen,credentials,field,route})
      setIsModalOpen(false)
      setImageUrl("docUpload:"+resp.key)
      setImageAdded(true)
      setIsDropOpen(false)
      // At the end of this, assuming success, we pass the image path to the handler on the specific component's level.
      handleEdit(resp.key)
  }