import "./index.css"

    import { t } from "@/utils/stringFormatting"
import React, { FormEventHandler, useRef, useState } from "react"
    import ReCAPTCHA from "react-google-recaptcha"


    type Props = {
        fields:any[],
        errors?:any[],
        inline?:boolean,
        submitName?:string,
        buttonClass?:string,
        handleSubmit?:Function,
        recaptcha?:any
    }

    const Form = ({fields,submitName,inline,buttonClass,handleSubmit,errors,recaptcha}:Props)=>{

        const reRef:any = useRef<ReCAPTCHA>()
        
        if(!errors){
            errors = []
        }
        const formValuesFromProps:any = {}
        fields.forEach(field=>{
            formValuesFromProps[field.name] = field?.placeholder||""
        })
        const [formValues,setFormValues] = useState(formValuesFromProps)

        const handleInputChange = (e:any,name:string)=>{
            const newFormValues = {...formValues};
            newFormValues[name] = e.target.value;
            setFormValues(newFormValues)
        }

        const handleFormSubmit:FormEventHandler = async (e)=>{
            e.preventDefault()
            let token
            // if(recaptcha){

            //     if(!reRef?.current||!reRef.current?.executeAsync){

            //         return
            //     }
            //     token = await reRef.current.executeAsync()
            //     return
            // }
            if(handleSubmit){
                handleSubmit(formValues)
            }
            const newFormValues = {...formValues}
            for(let field in newFormValues){
                newFormValues[field] = ""

            }
            setFormValues(newFormValues)
        }

        const handleRecaptchaChange = (value:any)=>{

        }

        return(
            <form onSubmit={handleFormSubmit}>
                {fields.map((field,key)=>{
                    const error = errors?.find(err=>(err.field===field.name))                    
                    switch(field.type){
                        case "number":
                            return(
                                <div key={key}>
                                {!inline&&<label>{field.name}</label>}
                                <input onChange={(e)=>handleInputChange(e,field.name)} value={formValues[field.name]} name={field.name} placeholder={field?.options?.placeholder||(inline&&t(field.name))||""} min={field?.options?.min||"0"} max={field?.options?.max||"99999999999999999999"} type={field.type}></input>
                                {error&&<div className="form-error">{t(error?.statement)}</div>}

                                </div>
                            );
                        case "image":
                                return(
                                    <div key={key}>
                                    <img src={formValues[field.name]} alt="" />
                                    {!inline&&<label>{field.name}</label>}
                                    <input onChange={(e)=>handleInputChange(e,field.name)} value={formValues[field.name]} name={field.name} placeholder={field?.options?.placeholder||(inline&&t(field.name))||""} min={field?.options?.min||"0"} max={field?.options?.max||"99999999999999999999"} type={field.type}></input>
                                    {error&&<div className="form-error">{t(error?.statement)}</div>}

                                    </div>
                            );
                        case "text":
                            return(
                                <div key={key}>
                                {!inline&&<label>{field.name}</label>}
                                <input onChange={(e)=>handleInputChange(e,field.name)} value={formValues[field.name]} name={field.name} placeholder={field?.options?.placeholder||(inline&&t(field.name))||""} min={field?.options?.min||"0"} max={field?.options?.max||"99999999999999999999"} type={field.type}></input>
                                {error&&<div className="form-error">{t(error?.statement)}</div>}

                                </div>
                            )
                            case "email":
                                return(
                                    <div key={key}>
                                    {!inline&&<label>{field.name}</label>}
                                    <input onChange={(e)=>handleInputChange(e,field.name)} value={formValues[field.name]} name={field.name} placeholder={field?.options?.placeholder||(inline&&t(field.name))||""} type={field.type}></input>
                                    {error&&<div className="form-error">{t(error?.statement)}</div>}
                                    </div>
                                )
                            case "textArea":
                                    return(
                                        <div key={key}>
                                        {!inline&&<label>{field.name}</label>}
                                        <textarea onChange={(e)=>handleInputChange(e,field.name)} value={formValues[field.name]} name={field.name} placeholder={field?.options?.placeholder||(inline&&t(field.name))||""}></textarea>
                                        {error&&<div className="form-error">{t(error?.statement)}</div>}

                                        </div>
                                    )
                            default:
                                return(
                                    <div key={key}>
                                    {!inline&&<label>{field.name}</label>}
                                    <input onChange={(e)=>handleInputChange(e,field.name)} value={formValues[field.name]} name={field.name} placeholder={field?.options?.placeholder||(inline&&t(field.name))||""} min={field?.options?.min||"0"} max={field?.options?.max||"99999999999999999999"} type={field.type}></input>
                                    {error&&<div className="form-error">{t(error?.statement)}</div>}

                                    </div>
                                )
                    }
                })}
                {recaptcha&&process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY?<ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                size="invisible"
                ref={reRef}
                onChange={handleRecaptchaChange}
                ></ReCAPTCHA>:<></>}
                <button className={`form-submit ${buttonClass?buttonClass:""}`}>{submitName||"submit"}</button>
            </form>
        )
    }
    export default Form
    
    
    