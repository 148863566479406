import Section from "../Section"
import "./index.css"

type Props = {
    image:string,
    heading:string,
    paragraph:string,
    ctaText:string,
    handleHeroCTA:any
}
    const Hero = ({image,heading,paragraph,ctaText,handleHeroCTA}:Props)=>{
        return(
            <div className="hero">

            <Section>
            <img className="hero-img" src={image}></img>

            <div className="overlay"></div>
            <div className="content">
            <h1>{heading}</h1>
            <h3>{paragraph}</h3>
            <button onClick={handleHeroCTA}>{ctaText}</button>     
            </div>  
            </Section>
            </div>
        )
    }
    export default Hero
    
    
    