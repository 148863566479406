import "./index.css"
    import BigContainer from "@/generics/BigContainer"
import EditComponentHeader from "@/generics/EditComponentHeader"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import handleToggleFeature from "@/handlers/handleToggleFeature"
import EditComponentProps from "@/types/editComponentProps"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "@/types/generics"
import route from "@/utils/route"
import DeleteCircle from "@/generics/DeleteCircle"
import { addLink, deleteLink, editLinks } from "@/api/links"
import { Context, ContextValueType } from "@/context"
import {useDebounce} from "use-debounce"

    const LinksSection = ({pageDetails,ownPage,isEditing,currentPageEdit,setCurrentPageEdit,pendingChanges,setPendingChanges}:EditComponentProps):JSX.Element=>{

        const links:Link[] = (pageDetails?.linksSection.links)||[]
        
        const componentName = "linksSection"
        const editingState = currentPageEdit?.[componentName]
        const on = editingState?.on

        const turnOnFeature = async ()=>{
            handleToggleFeature({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges})
        }

        if(!currentPageEdit?.[componentName]||(on===undefined)){
            return<></>
        }

        const setComponentValues = (value:any)=>{
            const newCurrentPageEdit = {...currentPageEdit};
            newCurrentPageEdit[componentName] = value;
            setCurrentPageEdit(newCurrentPageEdit)
        }

        if(isEditing){
            return(
                <Section alternating={true}>
                <EditComponentHeader
                componentName="Links Section"
                on={on}
                turnOnFeature={turnOnFeature}
                ></EditComponentHeader>
                {on?<EditLinks
                    links = {editingState.links}           
                ></EditLinks>:<></>}
                </Section>
                )

            }
        if(!on){
            return<></>
        }
            return(
                <Section alternating={true}>
                    <SectionHeading
                    text={"Other Stuff"}
                    ></SectionHeading>
                        <BigContainer>
                            <div className="links-cont link-section">

                            {links.map((link,key)=>{
                                return<a className="external-link" key={key} href={route(link.href)}>{link.text}</a>
                            })}
                            </div>
                        </BigContainer>
                </Section> 
            )
}
    
const EditLinks = ({links}:{links:Link[]})=>{

    const [currentLinks,setCurrentLinks] = useState(links)
    const [delayedLinks] = useDebounce(currentLinks,1000)
    const context:ContextValueType = useContext(Context)
    const [updated,setUpdated] = useState(true)
    const {userName} = context.credentials;
    const [lastUpdated,setLastUpdated] = useState(new Date())

    const currentTime = new Date()
    const diff = (currentTime.getTime() - lastUpdated.getTime())/1000

    useEffect(()=>{
        editLinks({userName,links:delayedLinks})
    },[delayedLinks])

    const updateLinks = ()=>{
        
    }
    
    if(diff>10){
        updateLinks()
        setLastUpdated(new Date())
    }

    const handleChange = (e:any,index:number,field:string)=>{
        setLastUpdated(new Date())
        setUpdated(false)
        if(field!=="text"&&field!=="href"){
            return
        }
        const value = e.target.value
        const newLinks = [...currentLinks]
        newLinks[index][field] = value
        setCurrentLinks(newLinks)
        setUpdated(false)
    }

    const handleAddLink = async ()=>{
        const newLink = await addLink({userName})
        setCurrentLinks([...currentLinks,newLink])
    }

    const handleDelete = (e:any)=>{
        const linkId = e.target.dataset.id;
        const newLinks = [...currentLinks].filter(link=>link._id!==linkId)
        setCurrentLinks(newLinks)
        deleteLink(userName,linkId)
    }

    return<div className="link-section">
        {currentLinks.map((link,index)=>{
            return(<div className="link-edit-row">
                <div className="input-cont">
                <label>URL</label>
                <input data-id={link._id} onChange={(e)=>handleChange(e,index,"href")} placeholder="ENTER URL" value={link.href}></input>
                </div>
                <div className="input-cont">
                <label>LINK NAME</label>
                <input data-id={link._id} onChange={(e)=>handleChange(e,index,"text")} placeholder="ENTER LINK NAME" value={link.text}></input>
                </div>
                <DeleteCircle
                _id={link?._id||index.toString()}
                handleDelete={handleDelete}
        ></DeleteCircle>
            </div>)
        })}
        <button onClick={handleAddLink}>Add Link</button>
    </div>
}
    
    
export default LinksSection