import "./index.css"
    import EditComponentHeader from "@/generics/EditComponentHeader"
import ImageEditor from "@/generics/ImageEditor"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import Socials from "@/generics/Socials"
import SocialSymbol from "@/generics/SocialSymbol"
import handleToggleFeature from "@/handlers/handleToggleFeature"
import EditComponentProps from "@/types/editComponentProps"
import SocialsSectionType, { SocialsType } from "@/types/socialsSectionType"
import checkShouldRender from "@/utils/checkShouldRender"
import React from "react"

    const SocialsSection = ({pageDetails,ownPage,isEditing,currentPageEdit,setCurrentPageEdit,pendingChanges,setPendingChanges}:EditComponentProps)=>{
        const componentName = "socialsSection"
        const editingState = currentPageEdit?.[componentName]
        const on = editingState?.on

        const turnOnFeature = async ()=>{
            handleToggleFeature({currentPageEdit,setCurrentPageEdit,componentName,pendingChanges,setPendingChanges})
        }

        const setComponentValues = (value:any)=>{
            const newCurrentPageEdit = {...currentPageEdit};
            newCurrentPageEdit[componentName] = value;
            setCurrentPageEdit(newCurrentPageEdit)
        }

        if(on===undefined){
            return<></>
        }

        if(ownPage&&isEditing){
            return(
                <Section alternating={true}>
                <EditComponentHeader
                componentName="Socials"
                on={on}
                turnOnFeature={turnOnFeature}
                ></EditComponentHeader>
                {on?<EditSocials socials={editingState} setSocials={setComponentValues} pendingChanges={pendingChanges} setPendingChanges={setPendingChanges}></EditSocials>:<></>}
                </Section>
            )
        }
        if(!checkShouldRender({pageDetails,componentName})||(pageDetails===null)){
            return<></>
        }
        return(
            <Section alternating={true}>
            <SectionHeading
            text={"Socials"}
            ></SectionHeading>
            <Socials
                socials={pageDetails.socialsSection.socials}
            ></Socials>
        </Section>
        )
    }
    export default SocialsSection
    
    
    type EditSocialsProps = {
        socials:SocialsSectionType,
        setSocials:any
        pendingChanges:any,
        setPendingChanges:any
    }
    
    function EditSocials({socials,setSocials,pendingChanges,setPendingChanges}:EditSocialsProps){

        const handleEdit = (e:any)=>{
            const {value,name} = e.target
            const newElement:any = {...socials,socials:{...socials.socials}}
            newElement.socials[name] = value
            setSocials(newElement)
            const newChanges = {...pendingChanges}
            newChanges["socialsSection.socials."+name] = value
            setPendingChanges(newChanges)
        }

        const socialsList = socials.socials

        const socialsArray = []
        for (let key in socialsList){
            const social = {name:key,url:socialsList[key as keyof SocialsType]}
            socialsArray.push(social)
        }

        return(
            <div className="edit-section">
            <form>
                {socialsArray.map((social,index)=>{
                    return(<div key={index} className="edit-social-div">
                    <SocialSymbol size={40} name={social.name}></SocialSymbol>
                    <input name={social.name} onChange={handleEdit} value={social.url} placeholder={social.name+" url"}></input>
                    </div>)
                })}
            </form>
            </div>

        )
    }