import "./index.css"
    import React from "react"

    const Logo = ({text}:{text:string})=>{
        return(
            <div className="logo-cont">
                    <img className="logo" src="/logo.png"></img>
                    <h2>{text}</h2>
            </div>
        )
    }
    export default Logo
    
    
    