    import "./index.css"
    import React from "react"
    import { useContext } from "react"
import { Context } from "@/context"

    type Props = {
        modalTitle?:string,
        children?:JSX.Element,
        additionalClass?:string,
        setIsModalOpen?:any
    }

    const Modal = ({modalTitle,additionalClass,children,setIsModalOpen}:Props)=>{
        let setIsModalOpenResolved:any;
        const {setModalOpen}:{setModalOpen:React.Dispatch<React.SetStateAction<string|null>>} = useContext(Context)
        if(!setIsModalOpen){
            setIsModalOpenResolved = setModalOpen
        }else{
            setIsModalOpenResolved = setIsModalOpen
        }


        return(
            <div className={`modal-overlay ${additionalClass}-overlay`}>
            <div className={`modal ${additionalClass}`}>
                <button className="close-modal-button" onClick={()=>setIsModalOpenResolved(null)}>×</button>
                <section className="modal-content">
                {modalTitle&&<h1>{modalTitle}</h1>}
                {children}
                </section>

            </div>
            </div>
        )
    }
    export default Modal
    
    
    