    import login from "@/api/login"
import { Context, ContextValueType } from "@/context"
    import Form from "@/generics/Form"
import { validateLogin } from "@/utils/yup"
import React, { useContext, useState } from "react"

    const Login = ()=>{

        const [errors,setErrors]:[errors:{field:string,statement:string}[],setErrors:any] = useState([])
        const {setCredentials,setModalOpen}:ContextValueType = useContext(Context)
        const handleSubmit = async (formValues:any)=>{
            try{
                const validation = await validateLogin(formValues)
                if(validation.isError){
                    setErrors(validation.errors.map((error:string)=>{
                        return {field:error.split(" ")[0],statement:error}
                    }))
                    return
                }
                
                const data = await login(formValues,setCredentials)
                if(data.error){
                    setErrors([data.error])
                    return
                }
                setModalOpen(null)
            }catch(err){
                setErrors([])
                console.error(err)
            }
        }   


        return(
            <Form
            fields={[{name:"email",type:"email"},
            {name:"password",type:"password"},]}
            errors={errors}
            inline={true}
            submitName="Login"
            buttonClass="btn-primary"
            handleSubmit={handleSubmit}
            ></Form>
        )
    }
    export default Login
    
    
    