import "./index.css"

type Props = {
    text:string
}

    const SectionHeading = ({text}:Props)=>{
        return(
            <h2 className="section-heading">{text}</h2>
        )
    }
    export default SectionHeading
    
    
    