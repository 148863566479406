
import { Context, ContextValueType } from "@/context"
import React, { useContext } from "react"
import { FaEnvelope, FaInbox } from "react-icons/fa"
import ItemWithFloatCount from "../ItemWithFloatCount"

    const EnvelopeIcon = ()=>{
        const {messages}:ContextValueType = useContext(Context)
        return(
            <div className="env-cont">

            <ItemWithFloatCount
            count={messages.length}
            >
                <FaEnvelope size={30}></FaEnvelope>
            </ItemWithFloatCount>
                </div>
            // <div className="envelope">
            //     <FaEnvelope></FaEnvelope>
            // </div>
        )
    }
    export default EnvelopeIcon
    
    
    