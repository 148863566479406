import {fexPost} from "@/utils/fex";

const login = async (formValues:any,setCredentials:any)=>{
    const url = "/api/login"
    const data = await fexPost(url,formValues)
    if(data.error){
        return data
    }
    
    const credentials = {...data.user,accessToken:data.accessToken,userId:data.user._id}
    window.sessionStorage.setItem("micStanCred",JSON.stringify(credentials))
    window.location.href = `/users/${credentials.userName}`
    setCredentials(credentials)
    return data
}

export default login