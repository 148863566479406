import Credentials from "@/types/credentials"
import getSlug from "./getSlug";

type Arguments = {
    credentials:Credentials
}

const checkIfOwnPage = ({credentials}:Arguments)=>{
    const userName = credentials?.userName;
    const pageName = getSlug().substring(1)
    const ownPage = userName === pageName;
    return ownPage
}

export default checkIfOwnPage