import "./index.css"
import Section from "../Section"

type Element = {
    heading:string,
    image:string,
    paragraph:string
}

type Props = {
    elements:Element[]
}

    const ThreeGrid = ({elements}:Props)=>{

        return(
            <Section>
                <div className="three-grid-cont">
                    {elements.map((element,key)=>{
                        return(
                            <div key={key} className="three-grid-element">
                                <h2>{element.heading}</h2>
                                <img src={element.image}></img>
                                <p>{element.paragraph}</p>
                            </div>
                        )
                    })}
                </div>
            </Section>
        )
    }
    export default ThreeGrid
    
    
    