import "./index.css"
import {FaFacebook, FaInstagram, FaTiktok, FaTwitter, FaYoutube} from "react-icons/fa"
import { Context, ContextValueType } from "@/context"
import { useContext } from "react"

    const Socials = ({socials}:{socials:any})=>{

        return(
            <div className="socials">
                {socials?.facebook&&<a onClick={()=>{window.location.href = getSocialURL(socials.facebook)}}><FaFacebook size={60} className="social-icon"></FaFacebook></a>}
                {socials?.twitter&&<a onClick={()=>{window.location.href = getSocialURL(socials.twitter)}} ><FaTwitter size={60} className="social-icon"></FaTwitter></a>}
                {socials?.instagram&&<a onClick={()=>{window.location.href = getSocialURL(socials.instagram)}} ><FaInstagram size={60} className="social-icon"></FaInstagram></a>}
                {socials?.youtube&&<a onClick={()=>{window.location.href = getSocialURL(socials.youtube)}} ><FaYoutube size={60} className="social-icon"></FaYoutube></a>}
            </div>
        )
    }
    function getSocialURL(url:string){
        if(url.indexOf("://")>0){
            return url
        }else{
            return `http://${url}`
        }
    }
    export default Socials
    
    
    